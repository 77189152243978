.Toastify.Toastify.Toastify {
  @apply tw-font-sans;

  .Toastify__toast--error, .Toastify__toast--warning, .Toastify__toast--success {
    @apply tw-bg-white tw-shadow-lg;

    .Toastify__toast-body {
      @apply tw-text-grayscale-180;
    }
  }
}


