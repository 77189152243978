.landingPage {
  min-height: 100vh;
  background: url(../images/hero-cover.png) center center no-repeat;
  background-size: contain;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.landingPage::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
  background: rgba(0,19,52, .7);
  z-index: 2;
}

.content {
  background: white;
  padding: 30px;
  margin: 0 50px;
  border-radius: 8px;
  z-index: 3;
  max-width: 700px;
  width: 100%;
}