 .navBar {
   z-index: 10;
   display: flex;
   position: absolute;
   padding: 40px 0;
   align-items: center;
   justify-content: center;
   background: transparent;
   width: 100%;
   transition: .2s ease-in-out;
   user-select: none;
 }

 .wrapper {
   max-width: 1200px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 15px;
 }

 .collapseWrapper {
   justify-content: flex-end;
 }

 .navBar.fixed {
   position: fixed;
   background: white;
   padding: 15px 0;
   box-shadow: 0px 7px 20px rgba(62, 80, 115, 0.16);
 }

 .logo {
   display: flex;
   align-items: center;
   max-width: 230px;
   width: 100%;
   font-size: 12px;
 }

 .logo span {
   color: #1494FB;
   font-weight: bold;
 }

 .nav {
   font-size: 12px;
   display: flex;
   flex-wrap: nowrap;
   align-items: center;
 }

 .link {
   color: white !important;
 }

 .linkParent a::after {
   color: white;
 }

 .link:hover {
   color: white;
   text-decoration: underline;
 }

 .fixed .link,
 .fixed.link:hover,
 .fixed.linkParent,
 .fixed .linkParent a::after {
   color: #273d67 !important;
 }

 .subItem {
   font-size: 14px;
   font-weight: bold;
   color: #273d67 !important;
   background-color: transparent !important;
 }

 .subItem:hover {
   color: #273d67;
   background: transparent;
   text-decoration: underline;
 }

 .button {
   padding: 6px 10px;
   display: inline-block;
   transition: .1s ease-in-out;
 }

 .varA {
   background: rgba(255, 255, 255, 1);
   border: 2px solid #1494FB;
   color: #1494FB;
   margin-left: 10px;
   margin-right: 10px;
 }

 .varA:hover {
   text-decoration: none;
   color: #006ac0;
   border-color: #006ac0;
 }

 .varB {
   background: #1494FB;
   color: white;
   border-radius: 2px;
   padding: 8px 10px;
 }

 .varB:hover {
   color: white;
   background: #006ac0;
   text-decoration: none;
 }

 .toggler {
   border: none;
   height: 25px;
   width: 30px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 0;
 }

 .togglerLine {
   display: block;
   height: 3px;
   width: 100%;
   background: #1494FB;
 }

 @media (max-width: 991px) {
  .navBar {
    padding: 20px 0;
  }

  .wrapper {
    justify-content: space-between;
    padding: 0 15px;
  }

  .collapseWrapper {
    height: 100vh;
    z-index: -1;
    background: white;
    margin: 0 -15px;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link {
    color: #006ac0 !important;
    font-size: 14px;
    font-weight: bold;
  }

  .linkParent a::after {
    display: none;
  }

  .varA {
    margin: 10px 0;
  }
}