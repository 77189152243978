.footer {
  padding-top: 50px;
  background: white;
  margin-bottom: 20px;
}

.subFooter {
  padding: 10px 0;
  background: #f8f8f8;
  text-transform: uppercase;
  font-size: 12px;
}

.logoSmall {
  max-width: 150px;
}

.socialMediaWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
  margin-top: 10px;
}

.socialMediaWrapper a {
  color: black;
  display: inline-block;
  opacity: 0.5;
  transition: .2s ease-in-out;
}

.socialMediaWrapper a:hover {
  opacity: 1;
}

.linksTitle {
  padding: 5px 0;
  display: inline-block;
}

.footerLink {
  color: rgba(0, 0, 0, .7);
  position: relative;
  padding: 5px 0px;
  transition: .1s ease-in-out;
  display: inline-block;
}

.footerLink:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}

.footerLink::after {
  position: absolute;
  display: block;
  content: '';
  right: -15px;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, .2);
}

.footerLink.last::after {
  display: none;
}

.sectionTitle {
  margin-bottom: 10px;
  display: block;
}

.linksList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.linksList a {
  color: #000000;
  opacity: 0.65;
  font-size: 14px;
  padding: 5px 0;
  display: inline-block;
}

.podcastIcon {
  max-width: 150px;
  margin-bottom: 20px;
}

.badgesWrapper {
  display: flex;
  align-items: center;
}

.badge {
  max-width: 40px;
  margin-right: 15px;
}

.storeIcon {
  max-width: 90px;
  display: inline-block;
}

@media (max-width: 1199px) {
  .storeIcon {
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .footerLink::after {
    display: none;
  }

  .storeIcon {
    max-width: 100px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 10px 0;
  }

  .sectionTitle {
    margin-top: 30px;
  }

  .podcastIcon {
    display: block;
  }
}